<template>
  <section ref="pc_common" :class="`${classs} detect_deviece_${deviceType} state_${deviceType}`">
    <HeadBar :title="domainConfig['IAmTitle']" :logo="domainConfig['icon']"
      :deviceType="deviceType" />
    <slot />
    <FootBar :deviceType="deviceType" />
  </section>
</template>

<script>
import HeadBar from '@/pages/components/head.vue';
import FootBar from '@/pages/components/footer.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    HeadBar,
    FootBar
  },
  props: {
    classs: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      deviceType: 'deviceType',
      domainConfig: 'domainConfig',
    }),
  },
};
</script>